import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "item-options" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_icon_avatar = _resolveComponent("vue-icon-avatar")!

  return (_openBlock(), _createElementBlock("a", {
    href: $options.profileUrl,
    class: "d-flex align-items-center text-truncate",
    tabindex: "-1"
  }, [
    _createVNode(_component_vue_icon_avatar, { user: _ctx.item }, null, 8 /* PROPS */, ["user"]),
    _createElementVNode("span", {
      innerHTML: _ctx.highlight(_ctx.item.name)
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("a", {
        class: "ms-3",
        href: $options.profileUrl,
        title: "Przejdź do profilu użytkownika"
      }, _cache[0] || (_cache[0] = [
        _createElementVNode("i", { class: "fas fa-user" }, null, -1 /* HOISTED */)
      ]), 8 /* PROPS */, _hoisted_4),
      _createElementVNode("a", {
        class: "ms-3",
        href: $options.messageUrl,
        title: "Napisz wiadomość"
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("i", { class: "fas fa-comment" }, null, -1 /* HOISTED */)
      ]), 8 /* PROPS */, _hoisted_5),
      _createElementVNode("a", {
        class: "ms-3",
        href: $options.postsUrl,
        title: "Znajdź posty użytkownika"
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("i", { class: "fas fa-magnifying-glass" }, null, -1 /* HOISTED */)
      ]), 8 /* PROPS */, _hoisted_6)
    ])
  ], 8 /* PROPS */, _hoisted_1))
}