
import VueIconAvatar from '../../icon-avatar.vue';
import DecoratorMixin from '../mixin';

export default {
  mixins: [DecoratorMixin],
  components: {
    'vue-icon-avatar': VueIconAvatar,
  },
  computed: {
    profileUrl() {
      return this.$props.item.url;
    },
    messageUrl() {
      return '/User/Pm/Submit?to=' + this.$props.item.name;
    },
    postsUrl() {
      return '/Search?model=Topic&sort=date&user=' + this.$props.item.name;
    },
  },
};
