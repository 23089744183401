
import {confirmModal} from "../../plugins/modals";
import {VueTimeAgo} from '../../plugins/timeago.js';
import store from "../../store/index";
import {MessageFolder} from '../../types/models';
import VueAvatar from '../avatar.vue';
import {default as mixins} from '../mixins/user.js';
import VueUserName from '../user-name.vue';

export default {
  name: 'VueMessage',
  mixins: [mixins],
  components: {
    'vue-avatar': VueAvatar,
    'vue-username': VueUserName,
    'vue-timeago': VueTimeAgo,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    last: {
      type: Boolean,
      default: false,
    },
    clickableText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SENTBOX: MessageFolder.sentbox,
    };
  },
  methods: {
    deleteMessage() {
      confirmModal({
        message: 'Tej operacji nie będzie można cofnąć.',
        title: 'Usunąć tę wiadomość?',
        okLabel: 'Tak, usuń',
      }).then(() =>
        store.dispatch(`messages/${this.clickableText ? 'trash' : 'remove'}`, this.message),
      );
    },
  },
  computed: {
    isRead() {
      return this.message.folder !== MessageFolder.sentbox ? this.message.read_at !== null : true;
    },
    excerpt() {
      return this.clickableText ? (this.message.excerpt ? this.message.excerpt : '(kliknij, aby przeczytać)') : this.message.text;
    },
  },
};
