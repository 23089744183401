import { createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, renderList as _renderList, createBlock as _createBlock, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, vShow as _vShow, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_4 = { class: "media" }
const _hoisted_5 = { class: "d-none d-sm-block me-2" }
const _hoisted_6 = { class: "media-body" }
const _hoisted_7 = { class: "d-flex flex-nowrap" }
const _hoisted_8 = { class: "flex-shrink-0 me-auto" }
const _hoisted_9 = { class: "media-heading" }
const _hoisted_10 = { class: "list-inline mb-0 list-inline-bullet-sm microblog-statistic" }
const _hoisted_11 = { class: "list-inline-item" }
const _hoisted_12 = ["href"]
const _hoisted_13 = { class: "list-inline-item small" }
const _hoisted_14 = {
  key: 0,
  class: "list-inline-item small"
}
const _hoisted_15 = { class: "microblog-tags" }
const _hoisted_16 = {
  key: 0,
  class: "dropdown"
}
const _hoisted_17 = { class: "dropdown-menu dropdown-menu-end" }
const _hoisted_18 = {
  key: 3,
  class: "dropdown-divider"
}
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["href", "title"]
const _hoisted_21 = ["alt"]
const _hoisted_22 = { class: "card-body" }
const _hoisted_23 = { class: "card-title text-truncate" }
const _hoisted_24 = { class: "card-text text-truncate" }
const _hoisted_25 = { class: "text-muted" }
const _hoisted_26 = {
  key: 1,
  class: "row mb-2"
}
const _hoisted_27 = ["onClick", "href"]
const _hoisted_28 = ["alt", "src"]
const _hoisted_29 = { class: "microblog-actions" }
const _hoisted_30 = ["aria-label"]
const _hoisted_31 = ["href"]
const _hoisted_32 = ["data-metadata", "data-url"]
const _hoisted_33 = {
  ref: "comments",
  class: "microblog-comments"
}
const _hoisted_34 = {
  key: 0,
  class: "show-all-comments"
}
const _hoisted_35 = {
  key: 1,
  method: "POST"
}
const _hoisted_36 = { class: "media microblog-input rounded border-top-0" }
const _hoisted_37 = { class: "me-2" }
const _hoisted_38 = { class: "media-body position-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_avatar = _resolveComponent("vue-avatar")!
  const _component_vue_username = _resolveComponent("vue-username")!
  const _component_vue_timeago = _resolveComponent("vue-timeago")!
  const _component_vue_tags = _resolveComponent("vue-tags")!
  const _component_vue_flag = _resolveComponent("vue-flag")!
  const _component_vue_form = _resolveComponent("vue-form")!
  const _component_vue_comment = _resolveComponent("vue-comment")!
  const _component_vue_comment_form = _resolveComponent("vue-comment-form")!
  const _component_vue_gallery = _resolveComponent("vue-gallery")!
  const _directive_profile = _resolveDirective("profile")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" we use below ID in mounted() method "),
    _createElementVNode("div", {
      id: `entry-${_ctx.microblog.id}`,
      class: "card card-default microblog"
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.microblog.deleted_at)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, " Ten wpis został usunięty. Możesz go przywrócić jeżeli chcesz. "))
          : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _withDirectives((_openBlock(), _createElementBlock("a", null, [
              _createVNode(_component_vue_avatar, _mergeProps(_ctx.microblog.user, {
                "is-online": _ctx.microblog.user.is_online,
                class: "i-45 d-block img-thumbnail"
              }), null, 16 /* FULL_PROPS */, ["is-online"])
            ])), [
              [_directive_profile, _ctx.microblog.user.id]
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("h5", _hoisted_9, [
                  _createVNode(_component_vue_username, {
                    user: _ctx.microblog.user
                  }, null, 8 /* PROPS */, ["user"])
                ]),
                _createElementVNode("ul", _hoisted_10, [
                  _createElementVNode("li", _hoisted_11, [
                    _createElementVNode("a", {
                      href: _ctx.microblog.url,
                      class: "small"
                    }, [
                      _createVNode(_component_vue_timeago, {
                        datetime: _ctx.microblog.created_at
                      }, null, 8 /* PROPS */, ["datetime"])
                    ], 8 /* PROPS */, _hoisted_12)
                  ]),
                  _createElementVNode("li", _hoisted_13, _toDisplayString(_ctx.microblog.views) + " " + _toDisplayString(_ctx.declination(_ctx.microblog.views, ['wyświetlenie', 'wyświetlenia', 'wyświetleń'])), 1 /* TEXT */),
                  (_ctx.microblog.is_sponsored)
                    ? (_openBlock(), _createElementBlock("li", _hoisted_14, " Sponsorowane "))
                    : _createCommentVNode("v-if", true)
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_vue_tags, {
                  tags: _ctx.microblog.tags,
                  class: "tag-clouds-md"
                }, null, 8 /* PROPS */, ["tags"])
              ]),
              (_ctx.isAuthorized)
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _cache[20] || (_cache[20] = _createElementVNode("button", {
                      class: "btn btn-xs border-0 text-muted",
                      type: "button",
                      "data-bs-toggle": "dropdown",
                      "aria-label": "Dropdown"
                    }, [
                      _createElementVNode("i", { class: "fa fa-ellipsis" })
                    ], -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_17, [
                      (_ctx.microblog.permissions.update)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            (!_ctx.microblog.deleted_at)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createElementVNode("a", {
                                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.edit(_ctx.microblog))),
                                    class: "dropdown-item",
                                    href: "javascript:"
                                  }, _cache[15] || (_cache[15] = [
                                    _createElementVNode("i", { class: "fas fa-pen-to-square fa-fw" }, null, -1 /* HOISTED */),
                                    _createTextVNode(" Edytuj")
                                  ])),
                                  _createElementVNode("a", {
                                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.deleteItem && $options.deleteItem(...args))),
                                    class: "dropdown-item",
                                    href: "javascript:"
                                  }, _cache[16] || (_cache[16] = [
                                    _createElementVNode("i", { class: "fas fa-trash-can fa-fw" }, null, -1 /* HOISTED */),
                                    _createTextVNode(" Usuń")
                                  ]))
                                ], 64 /* STABLE_FRAGMENT */))
                              : (_openBlock(), _createElementBlock("a", {
                                  key: 1,
                                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.restoreItem && $options.restoreItem(...args))),
                                  class: "dropdown-item",
                                  href: "javascript:"
                                }, _cache[17] || (_cache[17] = [
                                  _createElementVNode("i", { class: "fas fa-trash-arrow-up fa-fw" }, null, -1 /* HOISTED */),
                                  _createTextVNode(" Przywróć")
                                ]))),
                            (_ctx.microblog.permissions.moderate && !_ctx.microblog.deleted_at)
                              ? (_openBlock(), _createElementBlock("a", {
                                  key: 2,
                                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toggleSponsored(_ctx.microblog))),
                                  class: "dropdown-item",
                                  href: "javascript:"
                                }, _cache[18] || (_cache[18] = [
                                  _createElementVNode("i", { class: "fas fa-dollar-sign fa-fw" }, null, -1 /* HOISTED */),
                                  _createTextVNode(" Sponsorowany ")
                                ])))
                              : _createCommentVNode("v-if", true),
                            (_ctx.microblog.user.id !== _ctx.user.id)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_18))
                              : _createCommentVNode("v-if", true)
                          ], 64 /* STABLE_FRAGMENT */))
                        : _createCommentVNode("v-if", true),
                      (_ctx.microblog.user.id !== _ctx.user.id)
                        ? (_openBlock(), _createElementBlock("a", {
                            key: 1,
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.block(_ctx.microblog.user))),
                            href: "javascript:",
                            class: "dropdown-item"
                          }, _cache[19] || (_cache[19] = [
                            _createElementVNode("i", { class: "fas fa-fw fa-user-slash" }, null, -1 /* HOISTED */),
                            _createTextVNode(" Zablokuj użytkownika ")
                          ])))
                        : _createCommentVNode("v-if", true)
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _withDirectives(_createElementVNode("div", {
              class: _normalizeClass({'microblog-wrap': _ctx.isWrapped})
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.flags, (flag) => {
                return (_openBlock(), _createBlock(_component_vue_flag, {
                  key: flag.id,
                  flag: flag
                }, null, 8 /* PROPS */, ["flag"]))
              }), 128 /* KEYED_FRAGMENT */)),
              _createElementVNode("div", {
                ref: "microblog-text",
                innerHTML: _ctx.microblog.html,
                class: "microblog-text"
              }, null, 8 /* PROPS */, _hoisted_19),
              ($options.opg)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: $options.opg.metadata.url,
                    title: $options.opg.metadata.title,
                    class: "card microblog-opg",
                    target: "_blank"
                  }, [
                    _createElementVNode("div", {
                      alt: $options.opg.metadata.title,
                      class: "card-img-top",
                      style: _normalizeStyle(`background-image: url(${$options.opg.url})`)
                    }, null, 12 /* STYLE, PROPS */, _hoisted_21),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("h5", _hoisted_23, _toDisplayString($options.opg.metadata.title), 1 /* TEXT */),
                      _createElementVNode("p", _hoisted_24, _toDisplayString($options.opg.metadata.description), 1 /* TEXT */),
                      _createElementVNode("small", _hoisted_25, _toDisplayString($options.opg.metadata.url), 1 /* TEXT */)
                    ])
                  ], 8 /* PROPS */, _hoisted_20))
                : _createCommentVNode("v-if", true),
              ($options.images.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.images, (image, imageIndex) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: imageIndex,
                        class: "col-6 col-md-3"
                      }, [
                        _createElementVNode("a", {
                          onClick: _withModifiers(($event: any) => ($data.index = imageIndex), ["prevent"]),
                          href: image.url
                        }, [
                          _createElementVNode("img", {
                            alt: `Załącznik ${imageIndex}`,
                            class: "img-thumbnail",
                            src: image.thumbnail
                          }, null, 8 /* PROPS */, _hoisted_28)
                        ], 8 /* PROPS */, _hoisted_27)
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]))
                : _createCommentVNode("v-if", true),
              (_ctx.isWrapped)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 2,
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($options.unwrap && $options.unwrap(...args))),
                    class: "show-more"
                  }, _cache[21] || (_cache[21] = [
                    _createElementVNode("a", { href: "javascript:" }, [
                      _createElementVNode("i", { class: "fa fa-circle-right" }),
                      _createTextVNode(" Zobacz całość")
                    ], -1 /* HOISTED */)
                  ])))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */), [
              [_vShow, !_ctx.microblog.is_editing]
            ]),
            (_ctx.microblog.is_editing)
              ? (_openBlock(), _createBlock(_component_vue_form, {
                  key: 0,
                  ref: "form",
                  microblog: _ctx.microblog,
                  class: "mt-2 mb-2",
                  onCancel: _cache[6] || (_cache[6] = ($event: any) => (_ctx.edit(_ctx.microblog))),
                  onSave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.edit(_ctx.microblog)))
                }, null, 8 /* PROPS */, ["microblog"]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("a", {
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.checkAuth(_ctx.vote, _ctx.microblog))),
                onMouseenterOnce: _cache[9] || (_cache[9] = ($event: any) => (_ctx.loadVoters(_ctx.microblog))),
                "aria-label": $options.voters,
                href: "javascript:",
                class: "btn btn-gradient",
                "data-balloon-pos": "up",
                "data-balloon-break": ""
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass([{'fas text-primary': _ctx.microblog.is_voted, 'far': !_ctx.microblog.is_voted}, "fa-fw fa-thumbs-up"])
                }, null, 2 /* CLASS */),
                _createTextVNode(" " + _toDisplayString(' ') + " " + _toDisplayString(_ctx.microblog.votes) + " " + _toDisplayString(_ctx.declination(_ctx.microblog.votes, ['głos', 'głosy', 'głosów'])), 1 /* TEXT */)
              ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_30),
              _createElementVNode("a", {
                onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.checkAuth(_ctx.subscribe, _ctx.microblog))),
                href: "javascript:",
                class: "btn btn-gradient",
                title: "Wł/Wył obserwowanie tego wpisu"
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass([{'fas text-primary': _ctx.microblog.is_subscribed, 'far': !_ctx.microblog.is_subscribed}, "fa-fw fa-bell"])
                }, null, 2 /* CLASS */),
                _cache[22] || (_cache[22] = _createTextVNode(" " + _toDisplayString(' ') + " ")),
                _cache[23] || (_cache[23] = _createElementVNode("span", { class: "d-none d-sm-inline" }, "Obserwuj", -1 /* HOISTED */))
              ]),
              _createElementVNode("a", {
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.checkAuth($options.reply, _ctx.microblog.user))),
                href: "javascript:",
                class: "btn btn-gradient",
                title: "Odpowiedz na ten wpis"
              }, _cache[24] || (_cache[24] = [
                _createElementVNode("i", { class: "far fa-fw fa-comment" }, null, -1 /* HOISTED */),
                _createTextVNode(" " + _toDisplayString(' ') + " "),
                _createElementVNode("span", { class: "d-none d-sm-inline" }, "Komentuj", -1 /* HOISTED */)
              ])),
              _createElementVNode("a", {
                onClick: _cache[12] || (_cache[12] = _withModifiers(
//@ts-ignore
(...args) => ($options.copy && $options.copy(...args)), ["prevent"])),
                href: _ctx.microblog.url,
                class: "btn btn-gradient",
                title: "Kopiuj link do schowka"
              }, _cache[25] || (_cache[25] = [
                _createElementVNode("i", { class: "fas fa-share-nodes" }, null, -1 /* HOISTED */),
                _createTextVNode(" " + _toDisplayString(' ') + " "),
                _createElementVNode("span", { class: "d-none d-sm-inline" }, "Udostępnij", -1 /* HOISTED */)
              ]), 8 /* PROPS */, _hoisted_31),
              (_ctx.isAuthorized)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: "javascript:",
                    "data-metadata": _ctx.microblog.metadata,
                    "data-url": _ctx.microblog.url,
                    class: "btn btn-gradient",
                    title: "Zgłoś ten wpis"
                  }, _cache[26] || (_cache[26] = [
                    _createElementVNode("i", { class: "fas fa-flag" }, null, -1 /* HOISTED */),
                    _createTextVNode(" " + _toDisplayString(' ') + " "),
                    _createElementVNode("span", { class: "d-none d-sm-inline" }, "Zgłoś", -1 /* HOISTED */)
                  ]), 8 /* PROPS */, _hoisted_32))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_33, [
              (_ctx.microblog.comments_count > Object.keys(_ctx.microblog.comments).length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                    _createElementVNode("a", {
                      onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.loadComments(_ctx.microblog))),
                      href: "javascript:"
                    }, [
                      _cache[27] || (_cache[27] = _createElementVNode("i", { class: "far fa-comments" }, null, -1 /* HOISTED */)),
                      _createTextVNode(" Zobacz " + _toDisplayString(_ctx.declination($options.totalComments, ['pozostały', 'pozostałe', 'pozostałe'])) + " " + _toDisplayString($options.totalComments) + " " + _toDisplayString(_ctx.declination($options.totalComments, ['komentarz', 'komentarze', 'komentarzy'])), 1 /* TEXT */)
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.microblog.comments, (comment) => {
                return (_openBlock(), _createBlock(_component_vue_comment, {
                  key: comment.id,
                  comment: comment,
                  onReply: $options.reply
                }, null, 8 /* PROPS */, ["comment", "onReply"]))
              }), 128 /* KEYED_FRAGMENT */)),
              (_ctx.isAuthorized)
                ? (_openBlock(), _createElementBlock("form", _hoisted_35, [
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("div", _hoisted_37, [
                        _withDirectives((_openBlock(), _createElementBlock("a", null, [
                          _createVNode(_component_vue_avatar, {
                            photo: _ctx.user.photo,
                            name: _ctx.user.name,
                            initials: _ctx.user.initials,
                            class: "i-35 d-block img-thumbnail"
                          }, null, 8 /* PROPS */, ["photo", "name", "initials"])
                        ])), [
                          [_directive_profile, _ctx.user.id]
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_38, [
                        _createVNode(_component_vue_comment_form, {
                          microblog: $data.commentDefault,
                          ref: "comment-form"
                        }, null, 8 /* PROPS */, ["microblog"])
                      ])
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ], 512 /* NEED_PATCH */)
          ])
        ])
      ]),
      _createVNode(_component_vue_gallery, {
        images: $options.images,
        index: $data.index,
        onClose: _cache[14] || (_cache[14] = ($event: any) => ($data.index = null))
      }, null, 8 /* PROPS */, ["images", "index"])
    ], 8 /* PROPS */, _hoisted_1)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}