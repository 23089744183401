
import axios, {AxiosResponse} from 'axios';
import {mapGetters} from 'vuex';

import clickAway from "../../clickAway.js";
import store from '../../store/index';
import {Hit} from '../../types/hit';
import {SpecialKeys} from '../../types/keys';
import {Contexts, HitCategory, Models} from '../../types/search';
import {nextTick} from "../../vue";
import VueJobDecorator from './decorators/job';
import VueMicroblogDecorator from './decorators/microblog';
import VueTopicDecorator from './decorators/topic.vue';
import VueUserDecorator from './decorators/user.vue';
import VueWikiDecorator from './decorators/wiki';

export default {
  directives: {clickAway},
  store,
  components: {
    'vue-topic-decorator': VueTopicDecorator,
    'vue-job-decorator': VueJobDecorator,
    'vue-wiki-decorator': VueWikiDecorator,
    'vue-microblog-decorator': VueMicroblogDecorator,
    'vue-user-decorator': VueUserDecorator,
  },
  props: {
    value: String,
  },
  data() {
    return {
      isActive: false,
      isDropdownVisible: false,
      isHelpEnabled: false,
      isMobile: false,
      items: [] as Hit[],
      selectedIndex: -1,
      params: undefined,
      innerValue: '',
    };
  },
  watch: {
    innerValue(val: string) {
      if (val === '') {
        this.items = [];
      }
    },
  },
  created(this: Vue): void {
    this.makeParams();
    this.$data.innerValue = this.$props.value!;
  },
  mounted() {
    document.addEventListener('keydown', this.shortcutSupport);

    history.onpushstate = window.onpopstate = () => {
      // wait for location to really change before setting up new url
      setTimeout(() => this.makeParams(), 0);
    };
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this.shortcutSupport);
  },
  methods: {
    toggleMobile() {
      this.isMobile = !this.isMobile;
      if (this.isMobile) {
        nextTick(() => (this.$refs.input as HTMLInputElement).focus());
      }
    },
    showDropdown() {
      this.isActive = true;
      this.isDropdownVisible = true;
      this.loadItems();
    },
    hideDropdown() {
      if (this.isDropdownVisible) {
        this.isDropdownVisible = false;
      } else {
        this.innerValue = '';
      }
    },
    blurInput() {
      this.isActive = false;
    },
    clearInput() {
      this.innerValue = '';
      this.loadItems();
    },
    down() {
      this.isDropdownVisible = true;
      this.changeIndex(++this.selectedIndex);
    },
    up() {
      this.changeIndex(--this.selectedIndex);
    },
    hoverItem(index) {
      this.selectedIndex = index;
    },
    changeIndex(index) {
      const length = this.items.length;

      if (length > 0) {
        if (index >= length) {
          index = 0;
        } else if (index < 0) {
          index = length - 1;
        }

        this.selectedIndex = index;
      }
    },
    categoryLabel(category: Hit): string {
      return category.context !== undefined ? Contexts[category.model][category.context] : Models[category.model];
    },
    completion(event: KeyboardEvent): void {
      this.isHelpEnabled = this.innerValue === '?';

      if (this.isHelpEnabled || Object.values(SpecialKeys).includes(event.keyCode)) {
        return;
      }

      this.selectedIndex = -1; // reset position index after key pressed
      this.loadItems();
    },
    loadItems(): void {
      const headers: Record<string, string> = {};
      if (this.isAuthorized) {
        headers.Authorization = 'Bearer ' + store.state.user.user.token;
      }
      if (!this.endpoint) {
        return;
      }

      axios.get<any>(this.endpoint, {params: {q: this.innerValue || null}, headers}).then((response: AxiosResponse<any>) => {
        this.items = response.data;
        this.isDropdownVisible = true;
      });
    },
    shortcutSupport(event: KeyboardEvent): void {
      if (event.key === '?' && event.shiftKey && this.elementSupportSearchShortcut(event)) {
        event.preventDefault();
        (this.$refs.input as HTMLInputElement).focus();
      }
    },
    elementSupportSearchShortcut(event: KeyboardEvent) {
      const htmlElement = event.target as HTMLElement;
      if (htmlElement.isContentEditable) {
        return !htmlElement.closest(".editor-4play"); // make sure we found 4play editor
      }
      return !/^(?:input|textarea|select|button)$/i.test(htmlElement.tagName);
    },
    changeUrl(): void {
      if (this.selectedIndex === -1) {
        (this.$refs.search as HTMLFormElement).submit();
        return;
      }

      window.location.href = this.items.find(item => item.index === this.selectedIndex)!.url;
    },
    makeParams(): void {
      if (window.location.pathname !== '/Search') {
        this.params = undefined;
        return;
      }

      const params = new URLSearchParams(window.location.search);
      params.delete('q');
      params.delete('page');
      this.params = params;
    },
    makeDecorator(item: Hit): string {
      return `vue-${item.model.toLowerCase()}-decorator`;
    },
  },
  computed: {
    ...mapGetters('user', ['isAuthorized']),
    endpoint(): string | null {
      return this.innerValue.trim() === '' ? (this.isAuthorized ? '/completion/hub/' : null) : '/completion/';
    },
    categories() {
      let counter = 0;
      let result: HitCategory = {};

      this.items.forEach(item => {
        const key = `${item.model}-${item.context}`;
        let model, context;

        ({model, context} = item);

        if (!result[key]) {
          result[key] = {children: [], model, context};
        }

        result[key].children.push(item);
      });

      return Object.values(result).map(category => {
        category.children.forEach(child => Object.assign(child, {index: counter++}));
        return category;
      });
    },
  },
};
