import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($options.shouldShow)
    ? (_openBlock(), _createElementBlock("button", {
        key: 0,
        class: _normalizeClass([{'follows': $options.follows}, "btn btn-follow"]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.checkAuth($options.toggleState)))
      }, [
        _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fa fa-fw fa-check" }, null, -1 /* HOISTED */)),
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString($options.follows ? 'Obserwujesz' : 'Obserwuj'), 1 /* TEXT */)
        ])
      ], 2 /* CLASS */))
    : _createCommentVNode("v-if", true)
}