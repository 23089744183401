import { createTextVNode as _createTextVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "me-2" }
const _hoisted_2 = { class: "media-body" }
const _hoisted_3 = ["for"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_radio = _resolveComponent("vue-radio")!
  const _component_vue_modal = _resolveComponent("vue-modal")!

  return (_openBlock(), _createBlock(_component_vue_modal, { ref: "modal" }, {
    title: _withCtx(() => _cache[4] || (_cache[4] = [
      _createTextVNode(" Zgłoś materiał ")
    ])),
    buttons: _withCtx(() => [
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.closeModal && $options.closeModal(...args))),
        type: "button",
        class: "btn btn-secondary"
      }, "Anuluj"),
      _createElementVNode("button", {
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($options.sendReport && $options.sendReport(...args))),
        disabled: $data.isProcessing || $data.selectedType === null,
        type: "submit",
        class: "btn btn-danger danger"
      }, " Wyślij raport ", 8 /* PROPS */, _hoisted_4)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.types, (type, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "media"
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_vue_radio, {
                name: "type_id",
                modelValue: $data.selectedType,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.selectedType) = $event)),
                "checked-value": type.id,
                id: `type-${type.id}`
              }, null, 8 /* PROPS */, ["modelValue", "checked-value", "id"])
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("i", {
                class: _normalizeClass([$options.icon(type.name), "me-1"])
              }, null, 2 /* CLASS */),
              _createElementVNode("label", {
                for: `type-${type.id}`,
                class: "fw-bold"
              }, _toDisplayString(type.name), 9 /* TEXT, PROPS */, _hoisted_3),
              _createElementVNode("p", null, _toDisplayString(type.description), 1 /* TEXT */)
            ])
          ]))
        }), 128 /* KEYED_FRAGMENT */)),
        _withDirectives(_createElementVNode("textarea", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($data.text) = $event)),
          placeholder: "Dodatkowe informacje",
          name: "text",
          class: "form-control"
        }, null, 512 /* NEED_PATCH */), [
          [_vModelText, $data.text]
        ])
      ])
    ]),
    _: 3 /* FORWARDED */
  }, 512 /* NEED_PATCH */))
}