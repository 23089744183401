import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = {
  ref: "dropdown",
  class: "dropdown-alerts dropdown-menu dropdown-menu-end"
}
const _hoisted_2 = { class: "dropdown-header" }
const _hoisted_3 = {
  key: 0,
  class: "float-end"
}
const _hoisted_4 = {
  key: 0,
  class: "text-center p-3"
}
const _hoisted_5 = {
  key: 1,
  class: "text-center p-3 empty-placeholder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_notification = _resolveComponent("vue-notification")!
  const _component_perfect_scrollbar = _resolveComponent("perfect-scrollbar")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("li", {
    class: _normalizeClass({'open': $data.isOpen})
  }, [
    _createElementVNode("a", {
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => ($options.toggleDropdown && $options.toggleDropdown(...args)), ["prevent"])),
      href: "/User/Notifications",
      class: "nav-link",
      role: "button",
      "aria-haspopup": "true",
      "aria-expanded": "false"
    }, [
      _withDirectives(_createElementVNode("span", { class: "badge" }, _toDisplayString(_ctx.count), 513 /* TEXT, NEED_PATCH */), [
        [_vShow, _ctx.count > 0]
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-bell fa-fw" }, null, -1 /* HOISTED */))
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.unreadNotifications.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("a", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($options.openAll && $options.openAll(...args))),
                title: "Otwórz nowe w nowej karcie",
                href: "javascript:",
                class: "me-1"
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("i", { class: "fas fa-up-right-from-square" }, null, -1 /* HOISTED */)
              ])),
              _createElementVNode("a", {
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.markAllAsRead && $options.markAllAsRead(...args))),
                title: "Oznacz jako przeczytane",
                href: "javascript:"
              }, _cache[5] || (_cache[5] = [
                _createElementVNode("i", { class: "far fa-eye" }, null, -1 /* HOISTED */)
              ]))
            ]))
          : _createCommentVNode("v-if", true),
        _cache[6] || (_cache[6] = _createElementVNode("a", {
          title: "Przejdź do listy powiadomień",
          href: "/User/Notifications"
        }, "Powiadomienia", -1 /* HOISTED */))
      ]),
      _createVNode(_component_perfect_scrollbar, {
        ref: "scrollbar",
        class: "dropdown-modal",
        options: {wheelPropagation: false}
      }, {
        default: _withCtx(() => [
          (_ctx.notifications === null)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[7] || (_cache[7] = [
                _createElementVNode("i", { class: "fas fa-spinner fa-spin" }, null, -1 /* HOISTED */)
              ])))
            : _createCommentVNode("v-if", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
            return (_openBlock(), _createBlock(_component_vue_notification, {
              notification: notification,
              key: notification.id
            }, null, 8 /* PROPS */, ["notification"]))
          }), 128 /* KEYED_FRAGMENT */)),
          (Array.isArray(_ctx.notifications) && _ctx.notifications.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Brak powiadomień. "))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 512 /* NEED_PATCH */)
    ], 512 /* NEED_PATCH */), [
      [_vShow, $data.isOpen]
    ])
  ], 2 /* CLASS */)), [
    [_directive_click_away, $options.hideDropdown]
  ])
}