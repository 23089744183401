import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withKeys as _withKeys, vModelText as _vModelText, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  action: "/Search",
  role: "search",
  ref: "search",
  class: "flex-grow-1"
}
const _hoisted_2 = ["name", "value"]
const _hoisted_3 = {
  key: 1,
  class: "search-dropdown p-3"
}
const _hoisted_4 = {
  key: 2,
  class: "search-dropdown"
}
const _hoisted_5 = { class: "list-unstyled" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = ["onMouseover"]
const _hoisted_8 = {
  key: 0,
  class: "d-md-none navbar-nav ms-auto me-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'nav-search-mobile': $data.isMobile}, "nav-search"])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([{'active': $data.isActive}, "search-bar ms-md-4 me-md-4"])
    }, [
      _cache[12] || (_cache[12] = _createElementVNode("i", { class: "fas fa-magnifying-glass ms-2 me-2" }, null, -1 /* HOISTED */)),
      _createElementVNode("form", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.params, ([key, value]) => {
          return (_openBlock(), _createElementBlock("input", {
            type: "hidden",
            name: key,
            value: value
          }, null, 8 /* PROPS */, _hoisted_2))
        }), 256 /* UNKEYED_FRAGMENT */)),
        _withDirectives(_createElementVNode("input", {
          ref: "input",
          onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.showDropdown && $options.showDropdown(...args))),
          onKeydown: [
            _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => ($options.hideDropdown && $options.hideDropdown(...args)), ["prevent"]), ["esc"])),
            _cache[5] || (_cache[5] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => ($options.changeUrl && $options.changeUrl(...args)), ["prevent"]), ["enter"]))
          ],
          onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($options.completion && $options.completion(...args))),
          onKeyup: [
            _cache[3] || (_cache[3] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => ($options.up && $options.up(...args)), ["prevent"]), ["up"])),
            _cache[4] || (_cache[4] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => ($options.down && $options.down(...args)), ["prevent"]), ["down"]))
          ],
          onSearch: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => ($options.clearInput && $options.clearInput(...args))),
          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($data.innerValue) = $event)),
          type: "search",
          name: "q",
          autocomplete: "off",
          placeholder: "Wpisz \"?\", aby uzyskać pomoc lub wyszukaj"
        }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
          [_vModelText, $data.innerValue]
        ])
      ], 512 /* NEED_PATCH */),
      _createCommentVNode(" close mobile menu "),
      ($data.isMobile)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => ($options.toggleMobile && $options.toggleMobile(...args))),
            class: "btn nav-link"
          }, _cache[10] || (_cache[10] = [
            _createElementVNode("i", { class: "fa fa-2x fa-xmark" }, null, -1 /* HOISTED */)
          ])))
        : _createCommentVNode("v-if", true),
      ($data.isHelpEnabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[11] || (_cache[11] = [
            _createStaticVNode("<div class=\"row\"><div class=\"col-6 mb-2\"><code>&quot;foo bar&quot;</code> <small class=\"text-muted\">szukaj całych fraz</small></div><div class=\"col-6 mb-2\"><code>+foo -bar</code> <small class=\"text-muted\">wyklucz lub żądaj danego słowa w dokumencie</small></div><div class=\"col-6 mb-2\"><code>foo*</code> <small class=\"text-muted\">szuka fragmentów słów</small></div><div class=\"col-6 mb-2\"><code>foo~</code> <small class=\"text-muted\">szuka podobnych słów</small></div><div class=\"col-6 mb-2\"><code>user:foo</code> <small class=\"text-muted\">szukaj po autorze</small></div></div>", 1)
          ])))
        : ($data.isDropdownVisible && $data.items.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.categories, (category) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    _createElementVNode("li", _hoisted_6, [
                      _createElementVNode("span", null, _toDisplayString($options.categoryLabel(category)), 1 /* TEXT */)
                    ]),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.children, (child) => {
                      return (_openBlock(), _createElementBlock("li", {
                        class: _normalizeClass({'hover': child.index === $data.selectedIndex}),
                        onMouseover: ($event: any) => ($options.hoverItem(child.index))
                      }, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent($options.makeDecorator(child)), {
                          item: child,
                          value: $data.innerValue
                        }, null, 8 /* PROPS */, ["item", "value"]))
                      ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_7))
                    }), 256 /* UNKEYED_FRAGMENT */))
                  ], 64 /* STABLE_FRAGMENT */))
                }), 256 /* UNKEYED_FRAGMENT */))
              ])
            ]))
          : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    (!$data.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("a", {
            onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => ($options.toggleMobile && $options.toggleMobile(...args))),
            href: "javascript:",
            class: "nav-link"
          }, _cache[13] || (_cache[13] = [
            _createElementVNode("i", { class: "fa fa-magnifying-glass fa-fw" }, null, -1 /* HOISTED */)
          ]))
        ]))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */)), [
    [_directive_click_away, $options.blurInput]
  ])
}